import React from 'react';

const Title = () => {
    return (
        <div className="title">
            <h1>Générateur de citations</h1>
            <p><a href="https://quotepark.com/" target="_blank" rel="noopener noreferrer"><i>API quotes15</i></a></p>
        </div>
    );
};

export default Title;