import {useContext} from 'react';
import ThemeContext from "../services/ThemeContext";

const ToggleTheme = () => {
    const contextValue = useContext(ThemeContext)
    const handleChange = () => {
        contextValue.theme === "light"
            ? contextValue.updateTheme("dark")
            : contextValue.updateTheme("light")
    }
    return (

        <button
            className={
                contextValue.theme === "light"
                    ? "ld-button-dark"
                    : "ld-button-light"
            }
            value={contextValue.theme}
            onClick={handleChange}
            style={{marginBottom: "30px"}}
        >
            Light/Dark
        </button>

    );
};

export default ToggleTheme;