import React from 'react';

const Article = ({auteur, content}) => {

    return (
        <figure>
            <blockquote>
                <h2><i>"{[content].length >= 340
                    ? content.substring(0, 340) + "..."
                    : content}"</i></h2>
            </blockquote>
            <figcaption style={{marginTop: "1em"}}><cite>{auteur}</cite></figcaption>
        </figure>
    );
};

export default Article;