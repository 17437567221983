import ArticlesContainer from "./components/ArticlesContainer";
import {useEffect, useState} from "react";
import ThemeContext from "./services/ThemeContext";
import ToggleTheme from "./components/ToggleTheme";
import Reloader from "./components/Reloader";
import Title from "./components/Title";

function App() {
    // 1. Const et states
    const [theme, setTheme] = useState("dark")
    const contextValue = {
        theme,
        updateTheme: setTheme
    }
    const [data, setData] = useState([])
    const [auteur, setAuteur] = useState()
    const url = 'https://quotes15.p.rapidapi.com/quotes/random/?language_code=fr';


    // 2. comportement et fetch
    useEffect(() => {
        const options = {
            method: 'GET',
            headers: {
                'X-RapidAPI-Key': 'e81f06c0b4msh13a3383e6e4164bp1e07c5jsne8a7232e6b4d',
                'X-RapidAPI-Host': 'quotes15.p.rapidapi.com'
            }
        };

        fetch(url, options)
            .then(response => response.json())
            .then(response => {
                setData(response)
                setAuteur(response.originator.name)
                console.log(response)
            })
            .catch(err => console.error(err));

    }, [])

    const handleFetch = () => {
        const options = {
            method: 'GET',
            headers: {
                'X-RapidAPI-Key': 'e81f06c0b4msh13a3383e6e4164bp1e07c5jsne8a7232e6b4d',
                'X-RapidAPI-Host': 'quotes15.p.rapidapi.com'
            }
        };

        fetch(url, options)
            .then(response => response.json())
            .then(response => {
                setData(response)
                setAuteur(response.originator.name)
            })
            .catch(err => console.error(err));
    }


    return (
        <ThemeContext.Provider value={contextValue}>
            <div className={theme}>
                <Title/>
                <Reloader fetch={handleFetch}/>
                <ArticlesContainer quotes={data} auteur={auteur}/>
                <ToggleTheme/>
            </div>
        </ThemeContext.Provider>)
}

export default App;
