import AutorenewIcon from '@mui/icons-material/Autorenew';

const Reloader = ({fetch}) => {

    return (
        <div className="reloader">
            <button
                className="renew-button"
                onClick={fetch}
                style={{marginBottom: "30px"}}
            >
                <AutorenewIcon/>
            </button>
        </div>
    );
};

export default Reloader;